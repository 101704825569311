@import url('https://fonts.googleapis.com/css2?family=Workbench&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-container {
  width: 100%;
  max-width: 400px;
  /* Set a maximum width for the image container */
  margin: 0 auto;
  /* Center the image container */
}

.responsive-image {
  width: 100%;
  height: auto;
  /* Let the height adjust automatically to maintain aspect ratio */
}

@font-face {
  font-family: 'Steiner';
  src: url('../../public/fonts/steiner/Steiner.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.text-main {
  display: flex;
  font-family: Workbench-Delicate;
  background: linear-gradient(to top, white, #00FF85, #00FF85);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 8vw;
}

.text-main::after {
  content: '';
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 2vh;
}

.coming-soon-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 128px;
}

.coming-soon-text {
  font-family: "Workbench", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "BLED" 0,
    "SCAN" 0;
  font-size: 6vw;
  color: #00FF85;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  text-shadow: 0 0 4vw #00FF85, 0 0 5vw #00ff84af, 0 0 1vw #00ff8459;
}

.socials-text {
  font-family: "Workbench", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "BLED" 0,
    "SCAN" 0;
  font-size: 3vw;
  color: #00FF85;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  text-shadow: 0 0 3vw #00FF85;
}

.socials-hovered:hover {
  text-shadow: 0 0 3.1vw #00FF85, 0 0 1vw #00FF85;
  filter: url(#green-glow-strong);
}

.socials-glow {
  filter: url(#green-glow);
}

.socials-container {
  margin-bottom: 128px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 768px) {

  /* Adjust the breakpoint to your tablet size */
  .socials-container {
    margin-top: 256px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .socials-text {
    font-size: 8vw;
  }

  .main-container {
    gap: 32vh
  }

  .coming-soon-container {
    margin-top: 32px;
  }
}